.App
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.App button:hover
{
  background-color: rgba(187, 21, 104, 0.8);
}
.Header
{
  background-color: rgba(187, 21, 104, 1);
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  width: 100vw;
  display: flex;
}
.Header h1
{
  color: rgb(0, 0, 0);
  font-size: 15vmin;
}
.Body
{
  text-align: center;
}
.App button
{
  min-width: 100vw;
  min-height: 20vh;
  background-color: rgba(187, 21, 104, 0.555);
  border: 0px;
  font-size: 10vmin;
}
@media (max-aspect-ratio: 2/1) {
  .Body
  {
    font-size: 10vmin;
  }
}

@media (max-aspect-ratio: 1/2) {
  .Body
  {
    font-size: 15vmin;
  }
}